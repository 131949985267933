import dayjs from 'dayjs'
import { getAreaListByPacode } from '@/api/xqjz'

export function getObjectKeys<T>(obj: T) {
  return Object.keys(obj) as (keyof T)[]
}

/** 判断是否是空对象 */
export function isEmptyObject(obj: AnyObject) {
  return Object.keys(obj).length === 0
}

/** 复制对象（浅复制） */
export function copyObject<T extends Object>(target: T, source: T) {
  if (!source) return target
  getObjectKeys(source).forEach(key => target[key] = source[key])
}

export function partialCopyObject<T extends Object>(target: Partial<T>, source: T, ...args: (keyof T)[]) {
  args.forEach(key => target[key] = source[key])
}

/** 清空对象 */
export function clearObject(obj: AnyObject) {
  getObjectKeys(obj).forEach(key => delete obj[key])
}

export function formatDate (val: string | Date | undefined) {
  if (!val) return ''
  const date = dayjs(val)
  return date.format('YYYY-MM-DD')
}

export const formatTime = (val: string | Date | undefined) => {
  if (!val) return ''
  const date = dayjs(val)
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss').toString()
}

export function getFileMimeType(file: File) {
  // https://blog.csdn.net/weixin_33826268/article/details/94604439
  const map = {
      'FFD8FFE0': 'jpg',
      '89504E47': 'png',
      '47494638': 'gif',
      "52494646": 'webp',
      '25504446': 'pdf'
  }

  return new Promise((resolve, reject) => {
    const resolveFile = (uint8Array: Uint8Array) => {
      const array = uint8Array.slice(0, 4)
      let arr = [...array]
      let key = arr.map(item => item.toString(16)
          .toUpperCase()
          .padStart(2, '0'))
          .join('')
      resolve((map as any)[key])
    }

    if (file instanceof Uint8Array) {
      resolveFile(file)
      return
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => {
      try {
        
        let array = new Uint8Array(event.target?.result as ArrayBuffer)
        resolveFile(array)

      } catch (e) {
        reject(e);
      }
    };
  });
}

/** 获取表格指定列的合计数据 */
export function getTableColumnsSummary(props: Array<string>, cols: AnyArray, datas: AnyArray){
  //console.log(props, cols, datas);
  let fields: AnyObject[] = []
  let sums: number[] = []
  
  //先遍历哪些列要合计
  cols.forEach((item: AnyObject, index: number) => {
    if(props.indexOf(item.property) >= 0){
      fields.push({"nth": index, "key": item.property}) //要合计的列
      sums[index] = 0
    }else{
      sums[index] = Number.NaN //不求和的列
    }
  })
  
  //console.log(fields)
  //求和
  let lens = fields.length;
  datas.forEach((item: AnyObject) => {
    for(let ii = 0 ; ii < lens; ii++){
      let objx = fields[ii] 
      sums[objx.nth] += (item[objx.key] || 0)
    }
  })
  
  //console.log(sums)
  //返回字符串类型的数组
  return sums.map((numx, index) => {
    if(index === 0){
      return "小计"
    } else if(!window.isNaN(numx)){
      return numx.toFixed(2)
    } else {
      return ""
    }
  })
}

export async function getAreaListOfGx(areaList: AnyArray){ //获取广西的各个地市列表, 传入的是引用类型
	let resArea = await getAreaListByPacode("450000")
  
  if(!areaList){
    return []
  }
  
	//广西各个市级区域
	if(resArea.data && resArea.data.data){
		resArea.data.data.forEach((item: AnyObject) => {
			areaList.push({
				label: item.areaName,
				value: item.areaCode,
				areaid: item.areaId
			})
		})
	} else {
		areaList.push({label: "广西", value: "450000", areaid: 0})
	}
  
  return areaList
}
import user from './modules/user'
import app from './modules/app'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import getters from './getters'
import { createStore, Store } from 'vuex'
import { RootState } from '@/types/Store'

const store = createStore({
  modules: {
    app,
    user,
    permission,
    tagsView
  },
  getters
})

export default store as unknown as Store<RootState>

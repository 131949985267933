<template>
  <template v-if="!item.meta.hidden&&item.children" class="menu-wrapper">
    <el-menu-item
      v-if="hasOneShowingChildren(item.children) && !item.children[0].children&&!item.alwaysShow"
      :index="resolvePath(item.children[0].path)"
      :class="{'submenu-title-noDropdown':!isNest}"
    >
      <i :class="item.children[0].meta.icon" class="menu-icon"></i>
      <template #title>
        <span style="font-weight: bold"
          v-if="item.children[0].meta&&item.children[0].meta.title"
        >{{item.children[0].meta.title}}</span>
      </template>
    </el-menu-item>

    <el-sub-menu v-else :index="item.name||item.path">
      <template #title>
        <i :class="item.meta.icon" class="menu-icon"></i>
        <span style="font-weight: bold" v-if="item.meta&&item.meta.title">{{item.meta.title}}</span>
      </template>

      <template v-for="child in routes">
        <template v-if="!child.meta.hidden">
          <bar-item
            :is-nest="true"
            class="nest-menu"
            v-if="child.children&&child.children.length>0"
            :item="child"
            :key="child.name"
            :base-path="resolvePath(child.path)"
          ></bar-item>

          <el-menu-item :index="resolvePath(child.path)" :key="child.name" v-else>
            <i :class="child.meta.icon" class="menu-icon"></i>
            <span style="font-weight: bold" v-if="child.meta&&child.meta.title">{{child.meta.title}}</span>
          </el-menu-item>
        </template>
      </template>
    </el-sub-menu>
  </template>
</template>

<script>
import path from "path";

export default {
  name: "barItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  computed: {
    routes() {
      return this.item.children
        .slice()
        .sort((a, b) => a.meta.sortNo - b.meta.sortNo);
    }
  },
  methods: {
    hasOneShowingChildren(children) {
      // 启用如果只有一个子菜单自动提升为顶级独立菜单
      const showingChildren = children.filter(item => {
        return !item.meta.hidden;
      });
      if (showingChildren.length === 1) {
        // if (showingChildren[0].name === "dashboard") {
          // 如果是首页，则直接调整为顶级独立菜单
          return true;
        // }
      }
      return false;
    },
    resolvePath(...paths) {
      return path.resolve(this.basePath, ...paths);
    }
  }
};
</script>

import request from '@/utils/request'
import { getToken } from '@/utils/auth' // 验权

export function login(username: string, password: string) {
  return request({
    url: 'Check/Login',
    method: 'post',
    data: {
      username: username,
      password: password
      // AppKey: 'openauth'
    }
  })
}

export function getInfo() {
  return request({
    url: 'Check/GetUserInfo',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getUserName() {
  return request({
    url: 'Check/GetUserName',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getModules(token: string) {
  return request({
    url: 'check/getmodules',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getModulesTree(token: string) {
  return request({
    url: 'Check/GetModulesTree',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getOrgs(token: any) {
  return request({
    url: 'check/getorgs',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getSubOrgs(orgId: any) {
  return request({
    url: 'check/getSubOrgs',
    method: 'get',
    params: { orgId: orgId }
  })
}

export function getRole() {
  return request({
    url: 'check/GetRoles',
    method: 'get',
    params: { token: getToken() }
  })
}

export function getGuid(params: any) {
  return request({
    url: 'Common/GetUserGuid',
    method: 'get',
    params
  })
}

export function logout() {
  return request({
    url: 'check/logout',
    method: 'post'
  })
}
/** 第三方登录 */
export function thirdLogin(data: any) {
  return request({
    url: 'Check/ThirdSignIn',
    method: 'post',
    data: data
  })
}
/** 第三方绑定账号 */
export function thirdBinding(data: any) {
  return request({
    url: 'Check/ThirdBinding',
    method: 'post',
    data: data
  })
}
/** 判断账号密码 */
export function thirdCheckLogin(data: any) {
  return request({
    url: 'Check/ThirdCheckLogin',
    method: 'post',
    data: data
  })
}
/** 第三方用户信息 */
export function getThirdInfo() {
  return request({
    url: 'Check/GetThirdInfo',
    method: 'post'
  })
}
/** 修改密码 */
export function ChangePassword(data: any) {
  return request({
    url: 'Check/ChangePassword',
    method: 'post',
    data: data
  })
}

/** 用户权限 */
export function getRoles() {
  return request({
    url: 'Check/GetRoles',
    method: 'get',
    params: { token: getToken() }
  })
}
<template>
  <section class="app-main" ref="appMain">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in" @before-leave="onBeforeLeave" @after-leave="onAfterLeave">
        <keep-alive>
          <component :key="key" :is="Component" class="app-main-transition"></component>
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  methods: {
    onBeforeLeave() {
      document.querySelector('.app-main').style.overflow = 'hidden'
    },
    onAfterLeave() {
      document.querySelector('.app-main').style.overflow = 'auto'
    }
  }
  // watch: {
  //   $route(to, from) {
  //     this.$refs.appMain.scrollTop = 0
  //   }
  // }
};
</script>

<style scoped>
.app-main {
  /*84 = navbar + tags-view = 50 +34 */
  /* min-height: calc(100vh - 84px); */ /* me */
  /* height: calc(100vh - 35px); */
  position: relative;
  background-color: #efefef;
  box-sizing: border-box;
  /*  */
}
</style>

<style>
/* fade-transform */
/* .fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .5s;
  overflow: hidden;
}

.fade-transform-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
} */

</style>
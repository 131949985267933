export enum UserRole {
  /** 管理员 */
  Admin = 0,
  /** 业务员 */
  Worker = 4,
  /** 财务 */
  Financial = 5,
  /** 记账专员，2022年4月24日 韦志杰：客户端先写死，数据中心登录，如果角色Id为8 的只可用看到 生产机主管理和财务管理 两个菜单 */
  Accounter = 8,
}

export interface UserRoleType {
  identity: UserRole;
  name: string;
  roleType: number;
}
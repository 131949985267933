<template>
  <div>
    <div class="navbar" mode="horizontal">
      <div class="logo" style="font-size: 15px">
        <div class="logo-icon"></div>
        <span class="logo-title">桂东南木业交易平台-数据校验中心</span>
      </div>
      <hamburger
        class="hamburger-container"
        :toggleClick="toggleSideBar"
        :isActive="sidebar.opened"
      ></hamburger>
      <!-- <breadcrumb></breadcrumb> -->
      <div class="avatar-container">
        <el-dropdown trigger="click">
          <div class="avatar-wrapper">
            欢迎您，{{getUserName}}
            <i class="el-icon-caret-bottom"></i>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="isShow=true">
                <span>修改密码</span>
              </el-dropdown-item>
              <el-dropdown-item divided @click="logout">
                <span>退出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div>
      <el-dialog
        title="修改密码"
        v-model="isShow"
        width="600px"
        append-to-body
        custom-class="dialog-container"
      >
        <template #title>
          <span class="dialog-title">修改密码</span>
          <span class="dialog-sub-title"></span>
        </template>
        <div class="filter-container dialog-section-1st"></div>
        <div>
          <div style="width: 100%; margin-top: 25px">
            <div class="filter-item change-password-item">
              <span style="width: 70px; text-align: right; margin-right: 20px">原密码</span>
              <el-input
                type="password"
                v-model="uInfo.oldPas"
                placeholder="请输入原密码"
                maxlength="18"
                style="width:300px;"
                size="small"
              ></el-input>
            </div>
            <div class="filter-item change-password-item">
              <span style="width: 70px; text-align: right; margin-right: 20px">新密码</span>
              <el-input
                type="password"
                placeholder="请输入新密码"
                v-model="uInfo.newPas"
                maxlength="18"
                style="width:300px;"
                size="small"
              ></el-input>
            </div>
            <div class="filter-item change-password-item">
              <span style="width: 70px; text-align: right; margin-right: 20px">确认密码</span>
              <el-input
                type="password"
                placeholder="请再次输入新密码"
                v-model="uInfo.newPas2"
                maxlength="18"
                style="width:300px;"
                size="small"
              ></el-input>
            </div>
          </div>
        </div>
        <template #footer>
          <div style="margin-top: 30px;">
            <el-button
              @click="isShow = !isShow"
              size="small"
            >取消</el-button>
            <el-button
              type="primary"
              @click="changepas"
              size="small"
            >确认修改</el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Hamburger from "@/components/Hamburger";
import logo from "@/assets/logo.png?imageView2/1/w/80/h/80";
import { ChangePassword } from "@/api/login";
import router from '@/router';

export default {
  data: function () {
    return {
      logo: logo,
      isShow: false,
      uInfo: {},
    };
  },
  components: {
    Hamburger,
  },
  computed: {
    ...mapGetters(["sidebar"]),
    getUserName() {
      return this.$store.getters.nick;
    },
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch("ToggleSideBar");
    },
    /** 退出 */
    logout() {
      this.$store.dispatch("LogOut").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    },
    /** 弹窗修改密码 */
    changepas() {
      let oldPas = this.$store.getters.pass;
      if (this.uInfo.oldPas == oldPas) {
        if (
          this.uInfo.newPas.length > 1 &&
          this.uInfo.newPas == this.uInfo.newPas2
        ) {
          let pdata = {
            UserName: this.$store.getters.name,
            PassWord: this.uInfo.newPas,
          };
          ChangePassword(pdata).then((res) => {
            this.$message.success("密码修改成功");
            setTimeout(() => {
              this.logout();
            }, 1500);
          });
        } else this.$message.warning("请检查新密码");
      } else this.$message.warning("请检查原始密码");
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .logo-title {
    color: white;
    font-size: 18px;
    font-weight: bold;
    margin-left: 60px;
    cursor: default;
  }
  .hamburger-container {
    line-height: 44px;
    height: 44px;
    float: left;
    padding: 0 10px;
    color: white;
  }
  .screenfull {
    position: absolute;
    right: 90px;
    top: 16px;
    color: red;
  }
  .avatar-container {
    height: 44px;
    display: inline-block;
    position: absolute;
    width: 200px;
    text-align: right;
    line-height: 44px;
    right: 20px;
    color: white;
    .avatar-wrapper {
      font-size: 15px;
      cursor: pointer;
      .el-icon-caret-bottom {
        // right: -22px;
        // top: 12px;
        font-size: 16px;
      }
    }
  }
  .logo {
    min-width: 180px;
    text-align: center;
    float: left;
    .logo-icon {
      position: absolute;
      height: 34px;
      width: 34px;
      left: 16px;
      top: 7px;
      background: url('/static/img/login/logo.png');
      background-color: #FFFFFF;
      border-radius: 100px;
      background-size: cover;
      transform: translate(0 -3px);
      background-position: -2.5px 2.5px;
    }

  }
.navbar {
  border-bottom: 0 !important;
  background-color: #19A97B;
  box-shadow: 0 0 4px #DCDFE6;
  height: calc(var(--el-header-height) + 1px);

  :deep(.el-dropdown-menu__item) {
    text-align: left;
  }
  :deep(.avatar-wrapper) {
    color: #FFFFFF
  }
}
:deep(.el-dialog__header) {
  line-height: 24px;
}
.change-password-item {
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  margin-top: 10px;
  font-size: 15px;
}
</style>
<style>
.cpasDia .el-dialog {
  width: 600px;
  height: 400px;
  text-align: center;
}

.cpasDia .el-dialog__title {
  color: #6e7074;
  font-size: 15px;
}

.cpasDia .el-dialog__header {
  text-align: left;
  background: #f8f8f8;
  padding: 0 20px;
}

</style>
<template>
  <div class="app-wrapper" :class="classObj">
    <el-container>
      <el-header height="44px">
        <navbar></navbar>
      </el-header>
      <div>
        <sidebar class="sidebar-container"></sidebar>
        <div class="main-container">
          <tags-view></tags-view>
          <app-main></app-main>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";

export default {
  name: "layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
    TagsView,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("CloseSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
@import "src/styles/mixin.scss";
.app-wrapper {
  @include clearfix;
}
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: #efefef;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}
.el-header {
  padding: 0;
  line-height: 44px;
  // background-color: #333;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.el-container .sidebar-container {
  top: 44px !important;
  height: auto !important;
  border-right: 1px solid #dcdfe6;
}

.linkDivCss {
  border: 1px;
  background: #333;
  color: white;
  bottom: 0;
  width: 100%;
  height: 30px;
  padding-top: 7px;
  text-align: center;
  position: fixed;
  z-index: 1000;
}
</style>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
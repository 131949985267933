import { ElLoading } from 'element-plus'
import { App } from 'vue'

const loadingDirective = ElLoading.directive

const loadingConfigFactory = () => ({
  text: '加载中...',
  spinner: 'el-icon-loading',
  background: 'rgba(0, 0, 0, 0.15)',
  fullscreen: true
})

export const vLoading = {
  mounted(el: HTMLElement, binding: any) {
    const { text, spinner, background } = loadingConfigFactory()
    !el.getAttribute('element-loading-text') && el.setAttribute('element-loading-text', text)
    !el.getAttribute('element-loading-spinner') && el.setAttribute('element-loading-spinner', spinner)
    !el.getAttribute('element-loading-background') && el.setAttribute('element-loading-background', background)
    loadingDirective.mounted(el, binding)
  },
  updated(el: HTMLElement, binding: any) {
    loadingDirective.updated(el, binding)
  },
  unmounted(el: HTMLElement) {
    loadingDirective.unmounted(el)
  }
}

export const loadingService = () => {
  const loadingConfig = loadingConfigFactory()
  // loadingConfig.target = selector
  const serviceInstance = ElLoading.service(loadingConfig)
  return serviceInstance
}

export default (app: App) => {
  app.directive('loading', vLoading)
  app.config.globalProperties.$loading = loadingService
}
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { UserRole } from '@/types/Role'

/* Layout */
import Layout from '@/views/layout/Layout.vue';

// 角色访问路由控制
export const routerType = {
  // 财务
  [UserRole.Financial]: [
    'ShippingList',
    'ShippingRecord',
    'HwCategoryList',
    'HwGoodsList',
    'HwGroupList',
    'XQJZList',
    'LedgerGroup',
    'LedgerAccount',
    'LedgerAccountLog',
    'JHBList',
    'InvoiceList',
    'JHBAccountList',
    'ProductionList',
    'ProductionProcess',
    'ProductionConfig',
    'ProductSummaryList',
    'ProductBuyDetailList',
    'ProductionFeeDetail',
    'BankAccountFlowList',
    'InvoiceDetailList',
    'StockSumList',
    'BankPlatformAccountList',
    'PayProcessList',
    'PayManageList',
    'JHBConfigList',
    'InvoiceListManage',
    'InvoiceApplyList',
    'TradeList'
  ],
  // 业务员
  [UserRole.Worker]: [
    'ShippingList',
    'LedgerAccountLog',
    'XQJZList',
    'JHBList',
    'JHBAccountList',
    'PayProcessList'
  ],
  // 记账专员，2022年4月24日 韦志杰：客户端先写死，数据中心登录，如果角色Id为8 的只可用看到 生产机主管理和财务管理 两个菜单
  [UserRole.Accounter]: [
    'XQJZList',
    'InvoiceListManage',
    'InvoiceApplyList',
    'InvoiceDetailList',
    'ProductionConfig',
    'ProductionList',
    'ProductionProcess',
    'ProductSummaryList',
    'ProductBuyDetailList',
    'ProductionFeeDetail',
    'StockSumList'
  ]
}

export const createMenuRouter = (): RouteRecordRaw[] => {
  return [
    {
      path: '/',
      component: Layout,
      name: 'ShippingList',
      redirect: '/ShippingList',
      meta: { title: '货单管理', icon: 'el-icon-document-copy' }, 
      children: [
        {
          path: 'ShippingList',
          name: 'ShippingList',
          meta: { title: '货单管理'},
          component: () => import('@/views/shipping/ShippingList.vue')
        }, {
          path: 'LedgerAccountLog',
          name: 'LedgerAccountLog',
          meta: { title: '货单流水' },
          component: () => import('@/views/ledger/LedgerAccountLog.vue')
        }, {
          path: 'ShippingRecord',
          name: 'ShippingRecord',
          meta: { title: '货单录入' },
          component: () => import('@/views/shipping/ShippingRecord.vue')
        }
      ],
    }, {
      path: '/',
      component: Layout,
      name: 'HwCategoryList',
      redirect: '/HwCategoryList',
      meta: { title: '货物管理', icon: 'el-icon-box' }, 
      children: [
        {
          path: 'HwCategoryList',
          name: 'HwCategoryList',
          meta: { title: '货物分类管理'},
          component: () => import('@/views/goods/CategoryList.vue')
        }, {
          path: 'HwGoodsList',
          name: 'HwGoodsList',
          meta: { title: '货物信息管理' },
          component: () => import('@/views/goods/GoodsList.vue')
        }, {
          path: 'HwGroupList',
          name: 'HwGroupList',
          meta: { title: '货物分组管理' },
          component: () => import('@/views/goods/GroupList.vue')
        }/* , {
          path: 'HwRelationList',
          name: 'HwRelationList',
          meta: { title: '货物关系管理' },
          component: () => import('@/views/goods/RelationList.vue')
        } */
      ],
    }, {
      path: '/',
      component: Layout,
      name: 'LedgerGroupSub',
      redirect: '/LedgerGroup',
      meta: { title: '分账管理', icon: 'el-icon-set-up' },
      children: [
        {
          path: 'LedgerGroup',
          name: 'LedgerGroup',
          meta: { title: '规则分组管理'},
          component: () => import('@/views/ledger/LedgerGroupList.vue')
        }, {
          path: 'LedgerAccount',
          name: 'LedgerAccount',
          meta: { title: '生产机主分组'},
          component: () => import('@/views/ledger/LedgerAccountList.vue')
        }
      ],
    }, {
      path: '/',
      component: Layout,
      name: 'XQJZList',
      redirect: '/XQJZList',
      meta: { title: '生产机主管理' },
      children: [
        {
          path: 'XQJZList',
          name: 'XQJZList',
          meta: { title: '生产机主管理', icon: 'el-icon-user'},
          component: () => import('@/views/xqjz/XQJZList.vue')
        },
      ]
    }, {
      path: '/',
      component: Layout,
      name: 'JHBList',
      redirect: '/JHBList',
      meta: { title: '胶合板厂管理', icon: 'el-icon-house' },
      children: [
        {
          path: 'JHBList',
          name: 'JHBList',
          meta: { title: '胶合板厂管理' },
          component: () => import('@/views/jhbc/JHBCList.vue')
        }, {
          path: 'JHBAccountList',
          name: 'JHBAccountList',
          meta: { title: '胶合板厂企业账户' },
          component: () => import('@/views/jhbc/JHBAccountList.vue')
        }, {
          path: 'JHBConfigList',
          name: 'JHBConfigList',
          meta: { title: '胶合板厂配置管理' },
          component: () => import('@/views/jhbc/JHBConfigList.vue')
        }
      ]
    }, {
      path: '/',
      component: Layout,
      name: 'TradeList',
      redirect: '/TradeList',
      meta: { title: '交易平台', icon: 'el-icon-shopping-cart-1' },
      children: [
        {
          path: 'TradeList',
          name: 'TradeList',
          meta: { title: '交易平台', icon: 'el-icon-shopping-cart-1' },
          component: () => import('@/views/trade/TradeList.vue')
        }
      ]
    }, {
      path: '/',
      component: Layout,
      name: 'PayList',
      redirect: '/PayList',
      meta: { title: '支付管理', icon: 'el-icon-wallet' },
      children: [
        {
          path: 'BankPlatformAccountList',
          name: 'BankPlatformAccountList',
          meta: { title: '平台账户管理' },
          component: () => import('@/views/pay/BankPlatformAccountList.vue')
        }, {
          path: 'PayProcessList',
          name: 'PayProcessList',
          meta: { title: '支付路径管理' },
          component: () => import('@/views/pay/PayProcessList.vue')
        }, {
          path: 'BankAccountFlowList',
          name: 'BankAccountFlowList',
          meta: { title: '充值流水认领' },
          component: () => import('@/views/pay/BankAccountFlow.vue')
        }, {
          path: 'PayManageList',
          name: 'PayManageList',
          meta: { title: '后台支付管理' },
          component: () => import('@/views/pay/PayManageList.vue')
        }
      ]
    }, {
      path: '/',
      component: Layout,
      name: 'InvoiceList',
      redirect: '/InvoiceList',
      meta: { title: '财务管理', icon: 'el-icon-notebook-1' },
      children: [
        {
          path: 'InvoiceListManage',
          name: 'InvoiceListManage',
          meta: { title: '已回填发票管理'},
          component: () => import('@/views/finance/InvoiceListManage.vue')
        }, {
          path: 'InvoiceApplyList',
          name: 'InvoiceApplyList',
          meta: { title: '开票申请管理'},
          component: () => import('@/views/finance/InvoiceApplyList.vue')
        }, {
          path: 'InvoiceDetailList',
          name: 'InvoiceDetailList',
          meta: { title: '已开发票明细表'},
          component: () => import('@/views/finance/InvoiceDetailList.vue')
        }, {
          path: 'ProductionConfig',
          name: 'ProductionConfig',
          meta: { title: '生产配置' },
          component: () => import('@/views/finance/ProductionConfig.vue')
        }, {
          path: 'ProductionList',
          name: 'ProductionList',
          meta: { title: '生产数据列表' },
          component: () => import('@/views/finance/ProductionList.vue')
        }, {
          path: 'ProductSummaryList',
          name: 'ProductSummaryList',
          meta: { title: '生产数据汇总表' },
          component: () => import('@/views/finance/ProductSummaryList.vue')
        }, {
          path: 'ProductBuyDetailList',
          name: 'ProductBuyDetailList',
          meta: { title: '原木采购明细表' },
          component: () => import('@/views/finance/ProductBuyDetailList.vue')
        }, {
          path: 'ProductionFeeDetail',
          name: 'ProductionFeeDetail',
          meta: { title: '制造费用明细表' },
          component: () => import('@/views/finance/ProductionFeeDetail.vue')
        }, {
          path: 'StockSumList',
          name: 'StockSumList',
          meta: { title: '存货数量金额账' },
          component: () => import('@/views/finance/StockSumList.vue')
        }, {
          path: 'ProductionProcess',
          name: 'ProductionProcess',
          meta: { title: '订单流程', hidden: true },
          component: () => import('@/views/finance/ProductionProcess.vue')
        }
      ]
    }
  ]
}

export let constantRouterMap: RouteRecordRaw[] = [
  {
    path: '/login',
    meta: { sortNo: 0 },
    name: 'login',
    component: () => import('@/views/login/LoginNew.vue'),
  },
]

const createConstantRouter = () => {
  return createRouter({
    history: createWebHashHistory(),
    routes: constantRouterMap,
  })
}

let router = createConstantRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const routes = router.getRoutes()
  // reset router
  routes.forEach(route => {
    if (!constantRouterMap.some(r => r.name === route.name))
      router.removeRoute(route.name as string)
  })
}

export default router;

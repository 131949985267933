<template>
  <div style="transform: translateY(1px)">
    <el-menu
      mode="vertical"
      :show-timeout="200"
      :default-active="$route.path"
      :collapse="isCollapse"
      background-color="#2E413B"
      text-color="#FFFFFF"
      active-text-color="#00FFAE"
      style="margin-bottom:30px;"
      :collapse-transition="false"
      ref="menu"
      router
    >
      <el-scrollbar style="height: calc(100vh - 45px)">
        <sidebar-item v-for="route in routes" :key="route.name" :item="route" :base-path="route.path"></sidebar-item>
      </el-scrollbar>
    </el-menu>
    <div class="linkDivCss">
      <el-link disabled></el-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(["sidebar", "permission_routers"]),
    routes() {
      if (this.permission_routers) {
        return this.permission_routers
          .slice()
          .sort((a, b) => a.meta.sortNo - b.meta.sortNo);
      }
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
<style>
.linkDivCss {
  border: 1px;
  bottom: 10px;
  position: absolute;
} 
</style>
<style>
.el-submenu:not(.is-active) i {
  color: #FFFFFF !important
}
.el-submenu.is-active i {
  color: #00FFAE !important
}
.el-menu-item:not(.is-active) i {
  color: #FFFFFF !important
}
</style>
import router from './router'
import store from './store'
import NProgress from 'nprogress' // Progress 进度条
import 'nprogress/nprogress.css'// Progress 进度条样式
import { getToken } from '@/utils/auth' // 验权

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/loginNew'] // 不重定向白名单

router.beforeEach((to, from, next) => {
  NProgress.start()
  const userToken = getToken()
  if (whiteList.indexOf(to.path) !== -1) {
    // 访问的页面不需要登录态
    next()
  } else if (!userToken) {
    // 没有token缓存，直接重定向到login
    next('/login')
  } else {
    // 有缓存，但是路由为空，重新执行登录逻辑
    if (store.getters.permission_routers.length === 0) {
      // next('/login')
      store.dispatch('Login').then(() => {
        next(to)
      })
      .catch(() => next('/login'))
    } else {
      next()
    }
  }
})

// router.beforeEach((to, from, next) => {
//   NProgress.start()
//   if (whiteList.indexOf(to.path) !== -1) {
//     next()
//   } else if (getToken()) {
//     if (store.getters.modules === null || store.getters.inLoginProcess) {
//       store.dispatch('getOrgs').then(res => {
//         store.dispatch('GetInfo').then(res => { // 拉取用户信息
//           store.dispatch('GetModulesTree').then(moduleRes => { // 获取用户可访问的模块
//             const modules = moduleRes.Result
//             store.dispatch('getRole').then(() => {
//               const identities = store.getters.identities
//               // 无权限
//               if (identities.length === 0) {
//                 ElMessageBox.confirm('该用户无权限', '提示', {
//                   confirmButtonText: '确定',
//                   type: 'warning'
//                 }).then(() => {
//                   next('/login')
//                 }).catch(() => {
//                   next('/login')
//                 })
//                 throw new Error('')
//               } else if (identities[0] !== 'ADMIN') {
//                 return store.dispatch('getGuid')
//               }
//             }).then(() => {
//               store.dispatch('generateRoute').then(() => {
//                 store.commit('SET_IN_LOGIN_PROCESS', false)
//                 next({ ...to, replace: true })
//               })
//             })
//           })
//         }).catch((err) => {
//           next({ path: '/' })
//         })
//       })
//     } else {
//       next()
//     }
//   } else {
//     next('/login')
//   }
// })

router.afterEach(() => {
  NProgress.done() // 结束Progress
})

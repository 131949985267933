import { login, logout, getInfo, getOrgs, getModulesTree, getRole, getGuid } from '@/api/login'
import { getToken, setToken, removeToken, setPass, getPass } from '@/utils/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
import { UserRoleType, UserRole } from '@/types/Role'
import { Module } from 'vuex'
import { UserState, RootState } from '@/types/Store'
import { getC6Oauth, webLogin } from '@/api/auth'
import router from '@/router'
import store from '..'

export interface UserInfo {
  token: string,
  name: string,
  role: AnyArray,
  accessToken: string
}

const setUserInfoLocalStorage = (userInfo: AnyObject) => {
  const dataObj: UserInfo = {
    token: userInfo.token,
    name: userInfo.name,
    role: userInfo.role,
    accessToken: userInfo.accessToken
  }
  localStorage.setItem('userInfo', JSON.stringify(dataObj))
}

const getUserInfoLocalStorage = () => {
  const dataStr = localStorage.getItem('userInfo')
  if (!dataStr) return null
  return JSON.parse(dataStr) as UserInfo
}

const user: Module<UserState, RootState> = {
  state: {
    token: getToken(),
    name: '',
    pass: getPass(),
    nick: '',
    avatar: '',
    modules: null,
    roles: [],
    info: {},
    inLoginProcess: false,
    accessToken: '',
    c6AccessToken: ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_PASS: (state, pass) => {
      state.pass = pass
    },
    SET_NICK: (state, nick) => {
      state.nick = nick
    },
    SET_MODULES: (state, modules) => {
      state.modules = modules
    },
    SER_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_USER_INFO: (state, info) => {
      state.info = info
    },
    SET_IN_LOGIN_PROCESS: (state, status) => {
      state.inLoginProcess = status
    },
    SET_ACCESS_TOKEN: (state, token) => {
      state.accessToken = token
    },
    SET_C6_ACCESS_TOKEN: (state, token) => {
      state.c6AccessToken = token
    }
  },

  actions: {
    // 登录
    Login({ state, commit, dispatch }, loginInfo) {
      return new Promise<void>(async (resolve, reject) => {
        try {
          // 清空tagsView
          commit('DEL_ALL_VIEWS')

          const setUserInfoStore = (data: AnyObject) => {
            commit('SET_TOKEN', data.token)
            commit('SET_NICK', data.name)
            commit('SET_NAME', data.name)
            commit('SET_ACCESS_TOKEN', data.accessToken)
            commit('SER_ROLES', data.role.map((r: AnyObject) => r.rid))
          }

          // 如果提供了登录信息（用户名、密码），则先调登录接口获取token
          if (loginInfo) {
            const username = loginInfo.username.trim()
            const loginResponse = await webLogin({
              userName: username,
              passWord: loginInfo.password
            })
            const data = loginResponse.data.data
            setToken(data.token)
            // setPass(loginInfo.password)
            // commit('SET_PASS', loginInfo.password)
            setUserInfoStore(data)

            // 使用localStorage持久化用户信息
            setUserInfoLocalStorage(data)
          } else {
            const userInfo = getUserInfoLocalStorage()
            if (userInfo != null) {
              setToken(userInfo.token)
              setUserInfoStore(userInfo)
            }
          }

          const getC6AccessToken = async () => {
            const c6Resp = await getC6Oauth()
            const respData = c6Resp.data.data
            if (respData.access_token != null && !isNaN(Number(respData.expires_in))) {
              store.commit('SET_C6_ACCESS_TOKEN', respData.access_token)

              // token到期后，重新获取
              setTimeout(getC6AccessToken, respData.expires_in * 1000)
            }
          }

          // 获取C6端token
          try {
            await getC6AccessToken()
          } catch(e) {
            console.error(e);
          }

          // // 获取用户信息
          // await dispatch('GetInfo')
          // // 获取用户角色
          // await dispatch('getRole')

          let firstRouterName = await dispatch('generateRoute')
          resolve(firstRouterName)

        } catch (err) {
          console.error(err)
          reject(err)
        }
      })
    },
    // 获取用户组织机构
    getOrgs({ commit, state }) {
      return new Promise((resolve, reject) => {
        getOrgs(state.token).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          commit('SET_NAME', response.data.result.account);
          commit('SET_NICK', response.data.result.name)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户模块
    GetModulesTree({ commit, state }) {
      return new Promise((resolve, reject) => {
        getModulesTree((state as any).token).then(response => {
          if (response.data.Code == 500) {
            ElMessageBox.confirm('登录已超时，请【重新登录】', '超时提醒', {
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              commit('SET_TOKEN', '');
              removeToken()
              location.reload()
            })
          }
          else {
            commit('SET_MODULES', response.data.Result);
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_TOKEN', '')
          commit('SET_MODULES', [])
          removeToken()
          resolve(null)
        }).catch(error => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve(null)
          reject(error)
        })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve(null)
      })
    }
  }
}

export default user

import { BaseResponse, C6BaseResponse, C6PageRequest, C6PageResponse, PageRequest } from '@/types/API'
import { SourceType } from '@/types/Common'
import request, { c6DownLoad, c6Request, pureC6Request, pureRequest } from '@/utils/request'

// /** 获取旋切机主列表 */
// export function getXqjzList(params: PageRequest<AnyObject>) {
//   return request.get<BaseResponse>('xqjz-manager/xqjz-list', { params })
// }

/** 获取旋切机主列表 */
export function getXqjzList(params: C6PageRequest<XqjzListQueryParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/xqj/getXqjzInfoPage', { params })
}

// /** 获取旋切机主详情（普通用户） */
// export function xqjzInfo(xqjzid: number) {
//   return request.get<BaseResponse>(`xqjz-manager/xqjz-info/${xqjzid}`)
// }

// /** 获取旋切机主详情（财务用户） */
// export function xqjzInfoFinance(xqjzid: number) {
//   return request.get<BaseResponse>(`xqjz-manager/xqjz-info_-finance/${xqjzid}`)
// }

/** 查询旋切机主基础信息 */
export function getXqjzBaseInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzBaseInfo', { params: { xqjzId } })
}

/** 查询旋切机主设备信息 */
export function getXqjzDeviceInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzDeviceInfo', { params: { xqjzId } })
}

/** 查询旋切机主额外信息 */
export function getXqjzExifInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzExifInfo', { params: { xqjzId } })
}

/** 查询旋切机主财务信息 */
export function getXqjzConfigInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzConfig', { params: { xqjzId } })
}

/** 查询旋切机主营业执照信息 */
export function getXqjzBusinessLicenseInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzBusinessLicenseInfo', { params: { xqjzId } })
}

/** 查询旋切机主银行卡信息 */
export function getXqjzBankCardInfo(xqjzId: number) {
  return c6Request.get<C6BaseResponse>('backstage/xqj/getXqjzBankCardInfo', { params: { xqjzId } })
}

// /** 修改旋切机主场地信息 */
// export function updateXqjzInfoExif(data: AnyObject) {
//   return request.post<BaseResponse>(`xqjz-manager/update-xqjz-exif`, data)
// }

/** 修改旋切机主场地信息 */
export function updateXqjzInfoExif(data: AnyObject) {
  return c6Request.post<C6BaseResponse>(`backstage/xqj/updateXqjzExifInfo`, data)
}

// /** 修改旋切机主财务信息 */
// export function updateXqjzInfoFinance(xqjzid: number, data: AnyObject) {
//   return request.post<BaseResponse>(`xqjz-manager/update-xqjz-info_-finance`, { ...data, xqjid: xqjzid })
// }

/** 修改旋切机主财务信息 */
export function updateXqjzInfoFinance(data: AnyObject) {
  return c6Request.post<BaseResponse>(`backstage/xqj/updateXqjzFinanceInfo`, data)
}

// /** 添加旋切机主到黑名单 */
// export function addBlackUser(xqjzid: number) {
//   return request.post<BaseResponse>(`xqjz-manager/add-black-user/${xqjzid}`)
// }

/** 添加旋切机主到黑名单 */
export function addBlackUser(xqjzid: number) {
  return c6Request.post<C6BaseResponse>(`backstage/xqj/addXqjToBlackList`, xqjzid, { headers: {"Content-Type": "application/json"} })
}

// /** 旋切机主移除黑名单 */
// export function delBlackUser(xqjzid: number) {
//   return request.post<BaseResponse>(`xqjz-manager/del-black-user/${xqjzid}`)
// }

/** 旋切机主移除黑名单 */
export function delBlackUser(xqjzid: number) {
  return c6Request.post<C6BaseResponse>(`backstage/xqj/deleteXqjFromBlackList`, xqjzid, { headers: {"Content-Type": "application/json"} })
}

// /** 旋切机主移除红名单 */
// export function delRedUser(xqjIds: number[]) {
//   return request.post<BaseResponse>(`xqjz-manager/del-red-user`, xqjIds)
// }

// /** 旋切机主图片 */
// export function fileByXqjzid(xqjzid: number) {
//   return request.get<BaseResponse>(`file/file-by-xqjzid/${xqjzid}`)
// }

/** 旋切机主图片 */
export function fileByXqjzid(xqjzid: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`, { params: { sourceTypeId: SourceType.XQJZ, sourceId: xqjzid } })
}

// /** 获取旋切机主设备列表 */
// export function getDeviceList(xqjzid: number) {
//   return request.get<BaseResponse>(`xqjz-manager/device-list/${xqjzid}`)
// }

// /** 添加旋切机主设备信息 */
// export function addDevice(data: AnyObject) {
//   return request.post<BaseResponse>(`xqjz-manager/add-device`, data)
// }

// /** 修改旋切机主设备信息 */
// export function updateDevice(data: AnyObject) {
//   return request.post<BaseResponse>(`xqjz-manager/update-device`, data)
// }

// /** 修改旋切机主营业执照信息 */
// export function updateRegistration(data: AnyObject) {
//   return request.post<BaseResponse>(`xqjz-manager/update-xqjz-registration`, data)
// }

/** 修改旋切机主营业执照信息 */
export function updateRegistration(data: AnyObject) {
  return c6Request.post<C6BaseResponse>(`backstage/xqj/updateXqjzBusinessLicenseInfo`, data)
}

// /** 根据营业执照id获取图片资源 */
// export function getBusinessLicenseImages(businesslicenseid: number) {
//   return request.get<BaseResponse>(`file/file-by-business-license-id/${businesslicenseid}`)
// }

/** 根据营业执照id获取图片资源 */
export function getBusinessLicenseImages(businesslicenseid: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`, { params: { sourceTypeId: SourceType.BUSINESS_LICENSE, sourceId: businesslicenseid } })
}

// /** 根据旋切机主id获取设备信息 */
// export function getXqjzDeviceList(params: PageRequest<QueryXqjzDeviceParams>) {
//   return request.get<BaseResponse>(`xqj-device-manger/query-xqj-device-list`, { params })
// }

// /** 根据设备id获取图片 */
// export function fileByXqjDeviceId(deviceId: number) {
//   return request.get<BaseResponse>(`file/file-by-xqj-device-id/${deviceId}`)
// }

/** 根据设备id获取图片 */
export function fileByXqjDeviceId(deviceId: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`, { params: { sourceTypeId: SourceType.DEVICE, sourceId: deviceId } })
}


/** 根据父类编码获取区域 */
export function getAreaListByPacode(parentAreaCode: string) {
  return pureC6Request.get<C6BaseResponse>(`backstage/area/getAreasByParentCode`, { params: { parentAreaCode } })
}

/** 根据区域代码获取园区 */
export function getAreaParksByAreaCode(areaCode: string) {
  return pureC6Request.get<C6BaseResponse>(`backstage/area/getAreaParksByAreaCode`, { params: { areaCode } })
}

/** 根据区域编码获取区域 */
export function getAreaByAreaCode(areaCode: string) {
  return c6Request.get<C6BaseResponse>(`backstage/area/getAreaByAreaCode`, { params: { areaCode } })
}

// /** 设置旋切机园区 */
// export function setXqjAreaPark(xqjId: number, apId: number) {
//   return request.post<BaseResponse>(`xqjz-manager/change-set-xqj-area-park-info/${xqjId}/${apId}`)
// }

/** 获取旋切机主合同文件 */
export function exportXqjContract(xqjId: number) {
  return c6DownLoad('backstage/xqj/exportRcuContract', 'GET', { xqjId })
}

/** 删除旋切机主 */
export function invalidXqjz(xqjId: number) {
  return c6Request.post<C6BaseResponse<void>>('backstage/xqj/invalidXqjz', xqjId, { headers: {"Content-Type": "application/json"} })
}

export interface QueryXqjzDeviceParams {
  rcu_id: number
}

export interface XqjzListQueryParams {
  areaCode?: string,
  idCard?: string,
  isBlackUser?: number,
  isRedUser?: number,
  name?: string,
  phone?: string
}

import { defineComponent } from '@vue/runtime-core'
import { useLocale, useLocaleProps } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'

const Provider = defineComponent({
  props: {
    ...useLocaleProps
  },
  setup(props, { slots }) {
    useLocale()

    return () => (
      <>{slots.default?.()}</>
    )
  }
})

export default {
  name: 'App',
  components: {
    Provider
  },
  setup() {
    return {
      locale: zhCn
    }
  }
};

import axios, { AxiosRequestConfig, AxiosInstance, AxiosResponse, AxiosTransformer, Method } from 'axios'
import { ElMessage, ILoadingInstance } from 'element-plus'
import { getToken } from '@/utils/auth'
import { loadingService } from '@/utils/loading'
import store from '@/store'
import { formatDate, formatTime, getObjectKeys } from './common'
import dayjs from 'dayjs'

let requestCounter = 0
let loading: ILoadingInstance | undefined;

// 带token
const tokenInterceptor = (config: AxiosRequestConfig) => {
  const headers = config.headers
  const token = getToken()
  
  // 权限系统的问题，不是登录请求才带token
  if ((config.url as string).indexOf('login-web') <= 0) {
    if (token) headers['X-Token'] = token
  }
  
  headers['AccessToken'] = store.state.user.accessToken
  return config
}

const formatData = (data: any, dateFormatPattern: string) => {
  if (typeof data !== 'object' || !data) return
  getObjectKeys(data).forEach(key => {
    if (data[key] instanceof Date) {
      data[key] = dayjs(data[key]).format(dateFormatPattern).toString()
    } else if (typeof (data[key]) === 'object') {
      formatData(data[key], dateFormatPattern)
    } else if (data[key] === '') {
      data[key] = undefined
    }
  })
}

// 请求开始，展示Loading
const toastRequestInterceptor = (config: AxiosRequestConfig) => {

  if (!loading) {
    loading = loadingService()
  }
  requestCounter ++
  return config
}

// 所有的请求都结束之后隐藏Loading
const toastResopnseInterceptor = (axiosResponse: AxiosResponse) => {
  requestCounter --
  if (requestCounter === 0) {
    loading?.close()
    loading = undefined
  }
  return axiosResponse
}

// 网络错误提示
const toastErrorInterceptor = (err: any) => {
  console.error(err)
  requestCounter = 0
  loading?.close()
  ElMessage.error('网络错误')
  throw new Error(err.errMsg)
}

const throwError = (errMsg: any) => {
  if (typeof errMsg === 'string') ElMessage.error(errMsg)
  throw new Error(errMsg)
}

const isErrorCode = (code: number | undefined) => {
  if (code === undefined) return false
  if (code === 1) return true
  if (code === 0) return false
  return code >= 400 || code < 200
}

// 处理服务端返回错误
const serverErrorHandleInterceptor = (axiosResponse: AxiosResponse) => {
  const response = axiosResponse.data
  let errMsg;

  // 权限系统没过
  const authCode = response.code
  errMsg = response.message
  if (isErrorCode(authCode)) {
    // 返回登录页
    store.dispatch('FedLogOut').then(() => {
      location.reload()// 为了重新实例化vue-router对象 避免bug
    })
    throwError(errMsg)
  }

  // 处理外层错误信息
  const statusCode = response.statusCode
  errMsg = response.errors
  if (isErrorCode(statusCode)) {
    throwError(errMsg)
  }

  // 有可能错误信息在更深一层的嵌套
  const responseData = response.data
  if (responseData) {
    const contentCode = responseData.code
    errMsg = responseData.message
    if (isErrorCode(contentCode)) {
      throwError(errMsg)
    }
  }

  return axiosResponse
}

// 安装请求拦截器
const withInterceptors = (http: AxiosInstance) => {
  (http.defaults.transformRequest as AxiosTransformer[]).unshift(data => {
    formatData(data, 'YYYY-MM-DDTHH:mm:ss')
    return data
  })
  http.interceptors.request.use(tokenInterceptor)
  http.interceptors.request.use(toastRequestInterceptor)
  http.interceptors.response.use(toastResopnseInterceptor, toastErrorInterceptor)
  http.interceptors.response.use(serverErrorHandleInterceptor)
  return http
}

// 带token(c6)
const c6TokenInterceptor = (config: AxiosRequestConfig) => {
  const headers = config.headers
  headers['Authorization'] = 'Bearer ' + store.state.user.c6AccessToken
  headers['AccessToken'] = store.state.user.accessToken
  
  return config
}

// c6错误处理
const c6ServerErrorHandleInterceptor = (axiosResponse: AxiosResponse) => {
  const response = axiosResponse.data
  if (response.code != null && response.code != 0) throwError(response.msg || '')
  return axiosResponse
}

// 安装C6请求拦截器
const withC6Interceptors = (http: AxiosInstance) => {
  (http.defaults.transformRequest as AxiosTransformer[]).unshift(data => {
    formatData(data, 'YYYY-MM-DD HH:mm:ss')
    return data
  })
  http.interceptors.request.use(c6TokenInterceptor)
  http.interceptors.request.use(toastRequestInterceptor)
  http.interceptors.response.use(toastResopnseInterceptor, toastErrorInterceptor)
  http.interceptors.response.use(c6ServerErrorHandleInterceptor)
  return http
}

export const pureRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
})

export const pureC6Request = axios.create({
  baseURL: process.env.VUE_APP_C6_API,
  timeout: 50000
})
pureC6Request.interceptors.request.use(c6TokenInterceptor)

// 权限请求
export const authService = withInterceptors(
  axios.create({
    baseURL: process.env.AUTH_URL,
    timeout: 50000
  })
)

// 基本请求
export default withInterceptors(axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000
}))

// 文件请求
export const fileRequest = withInterceptors(axios.create({
  baseURL: process.env.VUE_APP_FILE_API,
  timeout: 50000
}))

// 胶合板厂端请求
export const c6Request = withC6Interceptors(
  axios.create({
    baseURL: process.env.VUE_APP_C6_API,
    timeout: 50000
  })
)

// 胶合板厂端下载文件
export const c6DownLoad = (url: string, method: Method, data: AnyObject) => {
  const http = axios
    .create({
      baseURL: process.env.VUE_APP_C6_API,
      timeout: 50000,
    })

  http.interceptors.request.use(c6TokenInterceptor)
  http.interceptors.request.use(toastRequestInterceptor)
  http.interceptors.response.use(toastResopnseInterceptor, toastErrorInterceptor)

  http
    .request({
      url,
      method,
      data: method == 'POST' ? data : {},
      params: method == 'GET' ? data: {},
      responseType: 'blob',
    })
    .then(async resp => {
      
      const contentType = resp.headers['content-type']
      const respData = resp.data

      if (contentType.indexOf('application/json') >= 0) {
        // 处理错误信息

        const text = await respData.text()
        console.log(text);
        const respJson = JSON.parse(text)
        if (respJson.code != null && respJson.code != 0) throwError(respJson.msg || '')

      } else if (contentType.indexOf('application/octet-stream') >= 0) {
        // 下载文件

        let url = window.URL.createObjectURL(new Blob([respData], { type: 'application/pdf' }))
        const fileName = decodeURI(resp.headers['content-disposition'].replace(/\w+;filename=\"(.*)\"/, '$1'))

        const a = document.createElement('a')
        a.style.display = 'none'
        a.download = fileName
        a.href = url
        a.click()
        if (document.body.contains(a)) {
          document.body.removeChild(a)
        }
      }
    })
}
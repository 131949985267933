/** 地理坐标 */
export interface GEOPosition {
  /** 经度 */
  lng: number;
  /** 纬度 */
  lat: number;
}

/**
 * 照片来源
 */
export enum SourceType {
  /** 合同 */
  CONTRACT = 1,
  /** 旋切机主 */
  XQJZ = 2,
  /** 胶合板厂 */
  JHBC = 3,
  /** 货单 */
  ORDER = 4,
  /** 分账规则 */
  LEDGER = 5,
  /** 营业执照 */
  BUSINESS_LICENSE = 6,
  /** 司机 */
  DRIVER = 7,
  /** 车辆 */
  VEHICLE = 8,
  /** 旋切机设备 */
  DEVICE = 9,
  /** 交易产品 */
  TRADE_GOODS = 10,
  /** 平台银行卡账号 */
  BANK_PLATFORM_ACCOUNT = 11,
  /** 退款凭证 */
  ORDER_PAY_RETURN = 17
}

/** 照片类型 */
export enum FileType {
  /** 身份证正面 */
  ID_CARD_FRONT = 1,
  /** 身份证背面 */
  ID_CARD_BACK = 2,
  /** 签名 */
  SIGNATURE = 3,
  /** 合同 */
  CONTRACT = 4,
  /** 装车照片 */
  LOAD = 5,
  /** 纸质货单照片 */
  ORDER = 6,
  /** 已卸货照片 */
  UNLOAD = 7,
  /** 空车照片（带车牌） */
  EMPTY_CAR = 8,
  /** 最终货单照片 */
  FINAL_ORDER = 9,
  /** 设备照片 */
  DEVICE = 10,
  /** 营业执照 */
  BUSINESS_LICENSE = 11,
  /** 驾驶证正面 */
  DRIVER_LICENSE_FRONT = 12,
  /** 驾驶证背面 */
  DRIVER_LICENSE_BACK = 13,
  /** 行驶证 */
  VEHICLE_LICENSE = 14,
  /** 营运证 */
  OPERATION_PERMIT = 15,
  /** 合同模板 */
  CONTRACT_TEMPLATE = 16,
  /** 交易货物 */
  TRADE_GOODS = 17,
  /** 盖章图片 */
  SEAL_IMG = 18,
  /** 订单货款退款凭证 */
  ORDER_GOODS_FEE_PAY_RETURN = 22,
  /** 订单服务费退款凭证 */
  ORDER_SERVICE_FEE_PAY_RETURN = 23
}

export const fileTypeNameMap = {
  [FileType.ID_CARD_FRONT]: '身份证正面',
  [FileType.ID_CARD_BACK]: '身份证背面',
  [FileType.SIGNATURE]: '签名',
  [FileType.CONTRACT]: '合同',
  [FileType.LOAD]: '装车照片',
  [FileType.ORDER]: '纸质货单照片',
  [FileType.UNLOAD]: '已卸货照片',
  [FileType.EMPTY_CAR]: '空车照片（带车牌）',
  [FileType.FINAL_ORDER]: '最终货单照片',
  [FileType.DEVICE]: '设备照片',
  [FileType.BUSINESS_LICENSE]: '营业执照',
  [FileType.DRIVER_LICENSE_FRONT]: '驾驶证正面',
  [FileType.DRIVER_LICENSE_BACK]: '驾驶证背面',
  [FileType.VEHICLE_LICENSE]: '行驶证',
  [FileType.OPERATION_PERMIT]: '营运证',
  [FileType.CONTRACT_TEMPLATE]: '合同模板',
  [FileType.TRADE_GOODS]: '交易货物',
  [FileType.SEAL_IMG]: '盖章图片',
  [FileType.ORDER_GOODS_FEE_PAY_RETURN]: '货款退回凭证',
  [FileType.ORDER_SERVICE_FEE_PAY_RETURN]: '服务费退回凭证'
}
import { BaseResponse } from '@/types/API'
import request from '@/utils/request'

export function webLogin(data: LoginInfo) {
  return request.post<BaseResponse>('register-login/login-web', data)
}

export function getC6Oauth() {
  return request.post<BaseResponse<C6LoginInfo>>('register-login/get-c6-oauth-token')
}

export interface LoginInfo {
  userName: string;
  passWord: string;
}

export interface C6LoginInfo {
  access_token: string,
  expires_in: number,
  refresh_token: string,
  scope: string,
  token_type: string
}
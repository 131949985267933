import { createApp } from 'vue'
import router from './router'
import store from './store'
import App from './App.vue'
import installElementPlus from './plugins/element'
import SvgIcon from '@/icons'
import installLoading from '@/utils/loading'
import { formatDate, formatTime } from '@/utils/common'
import 'element-plus/dist/index.css'
import './styles/theme/index.css'

import './permission'
import 'normalize.css'
import '@/styles/index.scss'
import '@/styles/global.scss'

// // 修改默认的时间格式化方式
Date.prototype.toISOString = function() {
  return formatTime(this)
}

const app = createApp(App).use(store).use(router)
installElementPlus(app)
app.component('svg-icon', SvgIcon)
installLoading(app)

app.mount('#app')
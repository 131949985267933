import Cookies from 'js-cookie'

const TokenKey = 'dc_token'
const Pass = 'pass'

export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token: string) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getPass() {
  return Cookies.get(Pass)
}
export function setPass(pass: string) {
  return Cookies.set(Pass, pass)
}

import { RouteRecordRaw  } from 'vue-router'
import { Module } from 'vuex'
import { constantRouterMap } from '@/router'
import router, { routerType, createMenuRouter, resetRouter } from '../../router'
import { PermissionState, RootState } from '@/types/Store'

// const groupRoutes = (data) => {
//   const parentPath = data.Item.Url
//   var newPath = {
//     path: parentPath || '/',
//     component: data.Item.Url == "/" ? Layout : data.Item.Url == "" ? Layout2 : () => import('@/views' + data.Item.Url.toLowerCase()),
//     meta: {
//       title: data.Item.Name,
//       sortNo: data.Item.SortNo,
//       icon: changeIcon(data.Item.IconName),
//       elements: data.Item && data.Item.Elements || ''
//     },
//     name: data.Item.Name,
//     hidden: false,
//     children: []
//   }
//   if (data.Children && data.Children.length > 0) {
//     data.Children.forEach(child => {
//       newPath.children.push(groupRoutes(child))
//     })
//   }
//   return newPath
// }
const permission: Module<PermissionState, RootState> = {
  state: {
    routers: [],
    // addRouters: []
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      // state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    generateRoute({ commit, rootState, state }) {
      return new Promise(resolve => {
        // 清除以前的路由
        resetRouter()
        let validRouter = createMenuRouter()
        let routerNames = (routerType as any)[rootState.user.roles[0] || 0]
        
        validRouter.forEach(route => {
          if (route.children) {
            const validChild: RouteRecordRaw[] = []
            route.children.forEach(child => {
              if (routerNames && routerNames.includes(child.path)) {
                validChild.push(child)
              }
            })
            route.children = validChild
            router.addRoute(route)
          }
        })
        validRouter = validRouter.filter(item => item.children && item.children.length > 0)
        commit('SET_ROUTERS', validRouter)
        resolve(routerNames[0])
      })
    }
  }
}


export default permission

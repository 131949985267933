import { GetterTree } from 'vuex'
import { RootState } from '@/types/Store'

const getters: GetterTree<RootState, RootState> = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  name: state => state.user.name,
  pass: state => state.user.pass,
  nick: state => state.user.nick,
  modules: state => state.user.modules,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  permission_routers: state => state.permission.routers,
  userInfo: state => state.user.info,
  inLoginProcess: status => status.user.inLoginProcess
}
export default getters
